import { Typography, Table, Anchor } from 'antd';
import { Fragment } from 'react';

export default function mkErrorCode() {
    let sdkEvents = [{
            event: "vmeeting.EVENT.SDK.SUCCESS",
            description: "SDK正常",
        }, {
            event: "vmeeting.EVENT.SDK.WARN",
            description: "SDK告警",
        }, {
            event: "vmeeting.EVENT.SDK.ERROR",
            description: "SDK异常",
        }
    ]

    let sessionEvents = [{
            event: "vmeeting.EVENT.SESSION.ROOM",
            description: "房间事件",
        }, {
            event: "vmeeting.EVENT.SESSION.MEMBER",
            description: "成员事件",
        }, {
            event: "vmeeting.EVENT.SESSION.MEDIA",
            description: "媒体事件",
        }
    ]

    let actionRooms = [{
            action: "vmeeting.ACTION.ROOM.PROCESS",
            description: "正在创建/加入房间",
        }, {
            action: "vmeeting.ACTION.ROOM.CONNECTED",
            description: "已经创建/加入房间",
        }, {
            action: "vmeeting.ACTION.ROOM.ONCHANGE",
            description: "房间信息变化",
        }, {
            action: "vmeeting.ACTION.ROOM.TERMINATED",
            description: "退出房间",
        }
    ]

    let actionMembers = [{
            action: "vmeeting.ACTION.MEMBER.INVITING",
            description: "成员正在加入房间",
        }, {
            action: "vmeeting.ACTION.MEMBER.JOINED",
            description: "成员已经加入房间",
        }, {
            action: "vmeeting.ACTION.MEMBER.LEAVED",
            description: "成员已经离开",
        }
    ]

    let actionMedias = [{
            action: "vmeeting.ACTION.MEDIA.PROCESS",
            description: "正在请求媒体（选看某些成员）",
        }, {
            action: "vmeeting.ACTION.MEDIA.ONCHANGE",
            description: "成员媒体变化",
        }
    ]

    let mediaDefinitions = [{
            key: "vmeeting.MEDIA.DEFINITION.HIGH",
            description: "高分辨率",
        }, {
            key: "vmeeting.MEDIA.DEFINITION.MID",
            description: "中分辨率",
        }, {
            key: "vmeeting.MEDIA.DEFINITION.LOW",
            description: "低分辨率",
        }
    ]
    let statusMembers = [{
            key: "vmeeting.STATUS.MEMBER.INVITING",
            description: "正在加入房间",
        }, {
            key: "vmeeting.STATUS.MEMBER.JOINED",
            description: "已经加入房间",
        }, {
            key: "vmeeting.STATUS.MEMBER.LEAVED",
            description: "已经离开房间",
        }
    ]


    let errorCodes = [{
            event: "vmeeting.CODE.SUCCESS",
            description: "成功",
            help: "无",
        }, {
            event: "vmeeting.CODE.REG_DISCONNECTED",
            description: "注册断开了",
            help: "排查：1.账号密码是否错误，2.信令服务器是否异常，3.本机与信令服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.APP_DISCONNECTED",
            description: "业务断开了",
            help: "排查：1.业务服务器是否异常，2.本机与业务服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.NO_PERMISSION",
            description: "无摄像头、麦克、扬声器权限",
            help: "排查：1.外接设备是否正常，2.用户是否进行过禁止获取设备权限操作 3.外接设备是否被其他应用占用",
        }, {
            event: "vmeeting.CODE.GET_PROFILE_TIMEOUT",
            description: "获取账户profile信息超时，websocket建立了连接，但是服务器没有发送profile的消息过来",
            help: "排查：1.业务服务器是否正常，2.本机与业务服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.GET_CONFIG_ERROR",
            description: "获取配置信息失败了",
            help: "排查：1. 服务器是否正常，2.本机与业务服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.AUTH_ERROR",
            description: "认证失败",
            help: "排查：1.appid，appCode是否匹配，账户是否存在",
        }, {
            event: "vmeeting.CODE.SDK_START_ERROR",
            description: "SDK启动失败",
            help: "排查：1.配置是否有误，2.信令服务器是否异常，3.本机与信令服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.LOGIN_CONFLICT",
            description: "账户在其他地方登陆",
            help: "排查：1.是否重复分配了同一账户给其他设备，2.本机的上一个SDK没停止，再次用同一个账户进行了SDK的初始化，两个实例在同抢",
        }, {
            event: "vmeeting.CODE.REG_TIMEOUT",
            description: "注册超时了",
            help: "排查：1.账号密码是否错误，2.媒体服务器是否异常，3.本机与媒体服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.SDK_STOPPED",
            description: "SDK停止了",
            help: "排查：1.是否是自己主动停止的SDK，2.信令服务器是否异常，3.本机与信令服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.MEDIA_NOT_READY",
            description: "媒体没有准备好",
            help: "排查：1.媒体服务器是否异常，2.本机与媒体服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.NO_RESPONSE",
            description: "创建或者加入房间无响应",
            help: "排查：1.信令/业务服务器是否异常，2.本机与信令/业务服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.INVALID_RESPONSE_MESSAGE",
            description: "无效的响应消息",
            help: "排查：1.业务服务器是否异常",
        }, {
            event: "vmeeting.CODE.ERROR_RESPONSE",
            description: "错误的响应",
            help: "排查：1.业务服务器是否异常，2.本机与业务服务器的连接是否通畅",
        }, {
            event: "vmeeting.CODE.NOT_EXIST_ROOM",
            description: "房间号不存在",
            help: "排查：1.房间是否正确创建，2.房间号是否输入有误",
        }
    ]
    return <Fragment>
        <Typography.Title>导航</Typography.Title>
        <Anchor className="web-anchor" affix={false}>
            <Anchor.Link href="#event" title="vmeeting.EVENT - 事件" />
            <Anchor.Link href="#action" title="vmeeting.ACTION - 动作" />
            <Anchor.Link href="#error-code" title="vmeeting.CODE - 错误码" />
            <Anchor.Link href="#definition" title="vmeeting.MEDIA.DEFINITION - 分辨率" />
            <Anchor.Link href="#status-member" title="vmeeting.STATUS.MEMBER - 成员状态" />
        </Anchor>
        <Typography>
            <Typography.Title id="event">事件</Typography.Title>
            <Table id="sdk" dataSource={sdkEvents} pagination={false} className="web-error-code-table">
                <Table.Column title="SDK事件" dataIndex="event" key="event" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>
            
            <Table id="session" dataSource={sessionEvents} pagination={false} className="web-error-code-table">
                <Table.Column title="会话事件" dataIndex="event" key="event" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="action">动作</Typography.Title>
            <Table dataSource={actionRooms} pagination={false} className="web-error-code-table">
                <Table.Column title="房间动作" dataIndex="action" key="action" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Table dataSource={actionMembers} pagination={false} className="web-error-code-table">
                <Table.Column title="成员动作" dataIndex="action" key="action" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Table dataSource={actionMedias} pagination={false} className="web-error-code-table">
                <Table.Column title="媒体动作" dataIndex="action" key="action" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="error-code">错误码</Typography.Title>
            <Table dataSource={errorCodes} pagination={false} className="web-error-code-table">
                <Table.Column title="错误码" dataIndex="event" key="event" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
                <Table.Column title="帮助" dataIndex="help" key="help"/>
            </Table>

            <Typography.Title id="definition">分辨率</Typography.Title>
            <Table dataSource={mediaDefinitions} pagination={false} className="web-error-code-table">
                <Table.Column title="分辨率" dataIndex="key" key="key" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="status-member">成员状态</Typography.Title>
            <Table dataSource={statusMembers} pagination={false} className="web-error-code-table">
                <Table.Column title="分辨率" dataIndex="key" key="key" width="30%"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>
        </Typography>
    </Fragment>
}