import moment from 'moment';

export class Alioss {

    constructor() {
        this.client = this.initOss()
    }

    initOss() {
        let alioss = {
            region: 'oss-cn-shenzhen',
            accessKeyId: 'LTAIInySYbttfIvB',
            accessKeySecret: 'LFbM6utT5uEfawWrgaObJbEiteRgN1',
            bucket: 'tv-android-version',
        }
        let OSS = require('ali-oss');
        return new OSS({
            region: alioss.region,
            accessKeyId: alioss.accessKeyId,
            accessKeySecret: alioss.accessKeySecret,
            bucket: alioss.bucket,
        });
    }

    downloadUrl(path, name) {
        let options = {
            'content-disposition': `attachment; filename=${encodeURIComponent(name)}`
        }
        return this.client.signatureUrl(path, options)
    }

    fileList(prefix = '', successHd = undefined, errorHd = undefined) {
        let that = this;
        let options = {
            prefix: prefix,
            delimiter: '/',
            marker: '',
            'max-keys': 1000,
        }
    
        function getErrorHd(e) {
            errorHd && errorHd()
        }
    
        function getFileSuccessHd(res, dirs) {
            let records = [];
            let prefixLen = prefix.length;
            let prefixSpLen = prefix.split('/').length;
            // console.log(prefix, res, dirs, prefixLen, prefixSpLen);
            
            if (res.objects) {
                res.objects.forEach((file, index) => {
                    let sps = file.name.split('/');
                    // console.log(prefixLen, prefixSpLen, sps)
                    if (sps.length === prefixSpLen && sps[sps.length - 1] !== "") {
                        records.push({
                            ...file,
                            lastModified: moment(new Date(file.lastModified)),
                            id: `file${index}`,
                            path: file.name,
                            name: prefixLen > 1 ? file.name.slice(prefixLen) : file.name,
                        })
                    }
                })
            }
    
            records.sort((a, b) => (a.lastModified && b.lastModified) ? b.lastModified.unix() - a.lastModified.unix() : false)
            successHd && successHd(prefix, records)
        }
        
        function getDirSuccessHd(dirRes) {
            // 获取文件
            options.delimiter = '';
            try {
                that.client.list(options).then((res) => getFileSuccessHd(res, dirRes.prefixes)).catch(getErrorHd);
            } catch (error) {
                console.error(error)
            }
        }
    
        // 获取文件夹
        try {
            that.client.list(options).then(getDirSuccessHd).catch(getErrorHd);
        } catch (error) {
            console.error(error)
        }
    }
}