import { Fragment } from 'react';
import { Typography, Table, Space, Button } from 'antd';

export default function mkVerion(versions, tblRowDownloadOnClick) {
    versions && versions.forEach((v, index) => { v.key = index });
    return <Fragment>
        <Typography>
            <Typography.Title>版本</Typography.Title>
        </Typography>
        <Table dataSource={versions}>
            <Table.Column title="版本" dataIndex="name" key="name" render={(value, record) => {
                return <Button type="link" className="web-action" onClick={(e) => tblRowDownloadOnClick(e, record)}>{value}</Button>
            }} />
            <Table.Column title="发布时间" dataIndex="lastModified" key="lastModified" render={(value, record) => {
                return value ? value.format("YYYY-MM-DD HH:mm:ss") : undefined;
            }}/>
            <Table.Column title="大小" dataIndex="size" key="size" render={(value, record) => {
                return `${(value / 1024).toFixed(2)} KB`;
            }}/>
            <Table.Column
                title="操作"
                key="action"
                render={(text, record) => (
                    <Space size="middle">
                        <Button type="link" className="web-action" onClick={(e) => tblRowDownloadOnClick(e, record)}>下载</Button>
                    </Space>
                )}
            />
        </Table>
    </Fragment>
}