import { Fragment } from 'react';
import { Typography, Anchor } from 'antd';
import { link } from '../../../../component/common';

export default function mkApi(onChange) {
    return <Fragment>
        <Typography.Title>导航</Typography.Title>
        <Anchor className="web-anchor" affix={false}>
            <Anchor.Link href="#init" title="初始化SDK" />
            <Anchor.Link href="#version" title="获取SDK版本号" />
            <Anchor.Link href="#create" title="创建房间" />
            <Anchor.Link href="#join" title="加入房间" />
            <Anchor.Link href="#get-room" title="获取房间信息" />
            <Anchor.Link href="#get-member" title="获取成员信息" />
            <Anchor.Link href="#get-media" title="获取媒体信息" />
            <Anchor.Link href="#exit" title="退出房间" />
            <Anchor.Link href="#destroy" title="销毁房间" />
            <Anchor.Link href="#bind" title="绑定音视频流" />
            <Anchor.Link href="#unbind" title="解绑音视频流" />
            <Anchor.Link href="#address-book" title="通讯录" />
            <Anchor.Link href="#invite" title="邀请成员" />
            <Anchor.Link href="#kick" title="踢出成员" />
            <Anchor.Link href="#sdk-listener" title="SDK事件Listener" />
            <Anchor.Link href="#session-listener" title="会话事件Listener" />
        </Anchor>
        <Typography>
            <Typography.Title>API</Typography.Title>
            <Typography.Title className="web-sub-title" id="init" level={5}>初始化SDK</Typography.Title>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var sdkConfig = {\n" +
                        "    appid: \"ab11f95e8bc211eb91061831bf6d8bd5\", // 这里填写appid\n" + 
                        "    appCode: \"b704237f8bc211eb860d1831bf6d8bd5\", // 这里填写appCode\n" + 
                        "    account: \"87c31a2db75848abb75180bd103a42c2\", // 这里填写account\n" + 
                        "    eventListener: sdkListener, // 这里填写初始化SDK的Listener\n" +
                        "}\n" + 
                        "var sdk = new vmeeting.Sdk(sdkConfig)\n" +
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">sdkConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "sdk-config", onChange)
            }}>vmeeting.SdkConfig</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="version" level={5}>获取SDK版本号</Typography.Title>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var version = sdk.version()\n"
                    }</span>
                </Typography.Paragraph>
            </pre>

            <Typography.Title className="web-sub-title" id="create" level={5}>创建房间</Typography.Title>
            <Typography.Text type="danger">前置条件：SDK初始化成功Listener收到成功事件<Typography.Link onClick={() => {
                link("error_code", "sdk", onChange)
            }}>vmeeting.EVENT.SDK.SUCCESS</Typography.Link>之后</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var createRoomConfig = {\n" +
                        "    subject: \"xxx讨论房间\", // 这里填写房间主题\n" + 
                        "    eventListener: roomListener, // 这里填写创建房间事件的Listener\n" + 
                        "}\n" + 
                        "sdk.createRoom(createRoomConfig);\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">createRoomConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "create-room-config", onChange)
            }}>vmeeting.CreateRoomConfig</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="join" level={5}>加入房间</Typography.Title>
            <Typography.Text type="danger">前置条件：SDK初始化成功Listener收到成功事件<Typography.Link onClick={() => {
                link("error_code", "sdk", onChange)
            }}>vmeeting.EVENT.SDK.SUCCESS</Typography.Link>之后</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var joinRoomConfig = {\n" +
                        "    accessCode: \"036584584\", // 这里填写房间号\n" + 
                        "    eventListener: roomListener, // 这里填写创建房间事件的Listener\n" + 
                        "}\n" + 
                        "sdk.joinRoom(joinRoomConfig);\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">joinRoomConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "join-room-config", onChange)
            }}>vmeeting.JoinRoomConfig</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="get-room" level={5}>获取房间信息</Typography.Title>
            <Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var room = sdk.getRoom();\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">room数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "room", onChange)
            }}>vmeeting.Room</Typography.Link></Typography.Text>
            
            <Typography.Title className="web-sub-title" id="get-member" level={5}>获取成员列表</Typography.Title>
            <Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var members = sdk.getMembers();\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">members数据结构是一个数组，里面每个对象的数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "member", onChange)
            }}>vmeeting.Member</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="get-media" level={5}>获取媒体信息</Typography.Title>
            <Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var media = sdk.getMedia();\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">media数据结构是一个数组，里面每个对象的数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "media-member", onChange)
            }}>vmeeting.MediaMember</Typography.Link></Typography.Text>


            <Typography.Title className="web-sub-title" id="exit" level={5}>退出房间</Typography.Title>
            <Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "sdk.exitRoom();\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>

            <Typography.Title className="web-sub-title" id="destroy" level={5}>销毁房间</Typography.Title>
            <Typography.Text type="danger">前置条件：已经创建过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件（只有创建者才可以销毁房间）</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "sdk.destroyRoom();\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>

            <Typography.Title className="web-sub-title" id="bind" level={5}>绑定音视频流</Typography.Title>
            <Typography.Text type="danger">前置条件：收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "// 绑定本端的视频\n" +
                        "sdk.bindLocalVideo(\"html_video_local\"); // 请先在html中增加标签<video id=\"html_video_local\" autoPlay muted></video>\n" +
                        "// 绑定远端的视频\n" +
                        "sdk.bindRemoteVideo(\"html_video_remote\"); // 请先在html中增加标签<video id=\"html_video_remote\" autoPlay muted></video>\n" +
                        "// 绑定远端的音频\n" +
                        "sdk.bindRemoteAudio(\"html_audio_remote\"); // 请先在html中增加标签<audio id=\"html_audio_remote\" autoPlay></audio>\n" +
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>

            <Typography.Title className="web-sub-title" id="unbind" level={5}>解绑音视频流</Typography.Title>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "// 解绑本端的视频\n" +
                        "sdk.unbindLocalVideo(\"html_video_local\");\n" +
                        "// 解绑远端的视频\n" +
                        "sdk.unbindRemoteVideo(\"html_video_remote\");\n" +
                        "// 解绑远端的音频\n" +
                        "sdk.unbindRemoteAudio(\"html_audio_remote\");\n" +
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>

            <Typography.Title className="web-sub-title" id="address-book" level={5}>通讯录</Typography.Title>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var userIds = ['f66d39ec0e724d6b854ba1ee96af617d'];\n" + 
                        "var addressBookConfig = {\n" +
                        "    pag: pag\n" + 
                        "    onOk: onOk\n" + 
                        "    onError: onError\n" + 
                        "}\n" + 
                        "sdk.addressBook(addressBookConfig);\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">addressBookConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "address-book-config", onChange)
            }}>vmeeting.AddressBookConfig</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="invite" level={5}>邀请成员</Typography.Title>
            <Typography.Paragraph><Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text></Typography.Paragraph> 
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var userIds = ['f66d39ec0e724d6b854ba1ee96af617d'];\n" + 
                        "var inviteMemberConfig = {\n" +
                        "    userIds: userIds\n" + 
                        "}\n" + 
                        "sdk.inviteMember(inviteMemberConfig);\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">inviteMemberConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "invite-member-config", onChange)
            }}>vmeeting.InviteMemberConfig</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="kick" level={5}>踢出成员</Typography.Title>
            <Typography.Paragraph><Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text></Typography.Paragraph>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var userIds = ['f66d39ec0e724d6b854ba1ee96af617d'];\n" + 
                        "var kickMemberConfig = {\n" +
                        "    userIds: userIds\n" + 
                        "}\n" + 
                        "sdk.kickMember(kickMemberConfig);\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">kickMemberConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "kick-member-config", onChange)
            }}>vmeeting.KickMemberConfig</Typography.Link></Typography.Text>

            <Typography.Title className="web-sub-title" id="view" level={5}>选看某些成员</Typography.Title>
            <Typography.Paragraph><Typography.Text type="danger">前置条件：已经创建或加入过房间，并且收到Listener的会话已建立<Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION.CONNECTED</Typography.Link>事件</Typography.Text></Typography.Paragraph>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "var member1 = {\n" +
                        "    userId: \"f66d39ec0e724d6b854ba1ee96af617d\"\n" + 
                        "    streamId: 0\n" + 
                        "    definition: vmeeting.MEDIA.DEFINITION.HIGH\n" + 
                        "}\n" + 
                        "var viewMediaMemberConfig = {\n" +
                        "    medias: [member1]\n" + 
                        "}\n" + 
                        "sdk.viewMediaMember(viewMediaMemberConfig);\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>
            <Typography.Text type="danger">viewMediaMemberConfig数据结构参考<Typography.Link onClick={() => {
                link("data_structure", "view-media-member-config", onChange)
            }}>vmeeting.ViewMediaMemberConfig</Typography.Link></Typography.Text>

            <Typography.Paragraph><Typography.Text type="danger">definition常量参考<Typography.Link onClick={() => {
                link("error_code", "definition", onChange)
            }}>vmeeting.MEDIA.DEFINITION</Typography.Link></Typography.Text></Typography.Paragraph>

            <Typography.Title className="web-sub-title" id="sdk-listener" level={5}>SDK事件Listener</Typography.Title>
            <pre className="code-pre">
                <Typography.Paragraph className="web-paragraph-code" code>
                    <span>{
                        "function sdkListener(e) {\n" + 
                        "    console.log(\"sdkListener type: \" + e.type);\n" + 
                        "    switch (e.type) {\n" +
                        "        case vmeeting.EVENT.SDK.SUCCESS:\n" +
                        "            // SDK初始化成功\n" +
                        "            console.log(\"SDK启动成功！现在你可以输入主题创建房间或输入房间号加入房间\")\n" + 
                        "            console.log(e.profile)\n" + 
                        "            break;\n" +
                        "       case vmeeting.EVENT.SDK.WARN:\n" +
                        "            // 告警（连接断开，正在重连等）\n" +
                        "            console.warn(\"SDK告警！错误码：\" + e.code + \" 详细信息：\" + e.description)\n" + 
                        "            break;\n" + 
                        "       case vmeeting.EVENT.SDK.ERROR:\n" +
                        "            // SDK初始化失败\n" +
                        "            console.error(\"SDK启动失败！错误码：\" + e.code + \" 详细信息：\" + e.description)\n" + 
                        "            break;\n" + 
                        "       default:\n" + 
                        "            break;\n" + 
                        "}\n" + 
                        ""
                    }</span>
                </Typography.Paragraph>
            </pre>

            <Typography.Title className="web-sub-title" id="session-listener" level={5}>会话事件Listener</Typography.Title>
                <pre className="code-pre">
                    <Typography.Paragraph className="web-paragraph-code" code>
                        <span>{
                        "function roomListener(e) {\n" +
                        "    console.log(\"roomListener type: \" + e.type);\n" +
                        "    switch (e.type) {\n" +
                        "        case vmeeting.EVENT.SESSION.ROOM: {\n" +
                        "            switch (e.action) {\n" +
                        "                case vmeeting.ACTION.ROOM.PROCESS: {\n" +
                        "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                        "                        console.error(\"创建/加入房间失败！错误码：\" + e.code + \" 详细信息：\" + e.description)\n" +
                        "                    } else {\n" +
                        "                        console.info(\"正在创建/加入房间！\", e.room)\n" + 
                        "                    }\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                case vmeeting.ACTION.ROOM.CONNECTED: {\n" +
                        "                    var room = e.room;\n" + 
                        "                    console.log(\"创建/加入房间成功。房间号：\" + room.accessCode + \" 主题：\" + room.subject)\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                case vmeeting.ACTION.ROOM.ONCHANGE: {\n" +
                        "                    var room = e.room;\n" + 
                        "                    console.log(\"房间信息变化。房间号：\" + room.accessCode + \" 主题：\" + room.subject)\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                case vmeeting.ACTION.ROOM.TERMINATED: {\n" +
                        "                    var room = e.room;\n" + 
                        "                    console.log(\"退出房间成功。房间号：\" + room.accessCode + \" 主题：\" + room.subject)\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                default:\n" +
                        "                    break;\n" +
                        "            }\n" +
                        "            break;\n" +
                        "        }\n" + 
                        "        case vmeeting.EVENT.SESSION.MEMBER:\n" +
                        "            switch (e.action) {\n" +
                        "                case vmeeting.ACTION.MEMBER.JOINED: {\n" +
                        "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                        "                        console.error(\"邀请成员失败！错误码：\", e.code, \" 详细信息：\", e.description)\n" +
                        "                    } else {\n" +
                        "                        console.info(\"成员已经加入房间！\", e.member)\n" + 
                        "                    }\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                case vmeeting.ACTION.MEMBER.LEAVED: {\n" +
                        "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                        "                        console.error(\"踢出成员失败！错误码：\", e.code, \" 详细信息：\", e.description)\n" +
                        "                    } else {\n" +
                        "                        console.info(\"成员离开房间！\", e.member)\n" + 
                        "                    }\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                case vmeeting.ACTION.MEMBER.INVITING: {\n" +
                        "                    console.error(\"成员正在加入房间\", e.member)\n" +
                        "                    break;\n" +
                        "                }\n" +
                        "                default:\n" +
                        "                    break;\n" +
                        "            }\n" +
                        "            break;\n" +
                        "        case vmeeting.EVENT.SESSION.MEDIA: {\n" +
                        "            switch (e.action) {\n" +
                        "                case vmeeting.ACTION.MEMBER.RROCESS: {\n" +
                        "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                        "                        console.error(\"选看成员失败！错误码：\", e.code, \" 详细信息：\", e.description)\n" +
                        "                    }\n" + 
                        "                    break;\n" +
                        "                }\n" +
                        "                case vmeeting.ACTION.MEMBER.ONCHANGE: {\n" +
                        "                    console.info(\"选看成员变化！\", e.media)\n" + 
                        "                    break;\n" +
                        "                }\n" +
                        "                default:\n" +
                        "                    break;\n" +
                        "            }\n" +
                        "            break;\n" +
                        "        }\n" +
                        "        default:\n" +
                        "            break;\n" +
                        "    }\n" +
                        "}\n" +
                        ""
                        }</span>
                    </Typography.Paragraph>
                </pre>
        </Typography>
    </Fragment>
}