import { Typography, Table, Anchor } from 'antd';
import { Fragment } from 'react';
import { link } from '../../../../component/common';

export default function mkDataStructure(onChange) {
    let sdks = [
        {
            key: "appid",
            mandatory: "是",
            type: "String",
            description: "你得到的Appid",
        }, {
            key: "appCode",
            mandatory: "是",
            type: "String",
            description: "你得到的AppCode",
        }, {
            key: "account",
            mandatory: "是",
            type: "String",
            description: "你得到的account列表中的某个account",
        }, {
            key: "eventListener",
            mandatory: "是",
            type: "Function",
            description: "SDK的事件Listener",
        }
    ]

    let createRooms = [
        {
            key: "subject",
            mandatory: "否",
            type: "String",
            description: "房间的主题",
        }, {
            key: "eventListener",
            mandatory: "是",
            type: "Function",
            description: "创建房间事件Listener（会话Listener）",
        }
    ]

    let joinRooms = [
        {
            key: "accessCode",
            mandatory: "是",
            type: "String",
            description: "房间号",
        }, {
            key: "eventListener",
            mandatory: "是",
            type: "Function",
            description: "加入房间事件Listener（会话Listener）",
        }
    ]

    let sdkEvents = [
        {
            key: "type",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "sdk", onChange)
            }}>vmeeting.EVENT.SDK</Typography.Link>,
            description: "事件类型",
        }, {
            key: "profile",
            mandatory: <div>type为<Typography.Link onClick={() => {
                link("error_code", "sdk", onChange)
            }}>vmeeting.EVENT.SDK.SUCCESS</Typography.Link>时必填</div>,
            type: <Typography.Link onClick={() => {
                document.getElementById('profile').scrollIntoView()
            }}>vmeeting.Profile</Typography.Link>,
            description: "账户信息",
        }, {
            key: "code",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "error-code", onChange)
            }}>vmeeting.CODE</Typography.Link>,
            description: "错误码",
        }, {
            key: "description",
            mandatory: "是",
            type: "String",
            description: "错误描述",
        }
    ]

    let sessionEvents = [
        {
            key: "type",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "session", onChange)
            }}>vmeeting.EVENT.SESSION</Typography.Link>,
            description: "事件类型",
        }, {
            key: "code",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "error-code", onChange)
            }}>vmeeting.CODE</Typography.Link>,
            description: "错误码",
        }, {
            key: "action",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "action", onChange)
            }}>vmeeting.ACTION</Typography.Link>,
            description: "动作",
        }, {
            key: "description",
            mandatory: "是",
            type: "String",
            description: "错误描述",
        }
    ]

    let profiles = [
        {
            key: "restart",
            mandatory: "是",
            type: "Boolean",
            description: "是否是重启的sdk",
        }, {
            key: "appid",
            mandatory: "是",
            type: "String",
            description: "Appid",
        }, {
            key: "appCode",
            mandatory: "是",
            type: "String",
            description: "AppCode",
        }, {
            key: "account",
            mandatory: "是",
            type: "String",
            description: "Account",
        }, {
            key: "userId",
            mandatory: "是",
            type: "String",
            description: "分配的用户id",
        }, {
            key: "userPassword",
            mandatory: "是",
            type: "String",
            description: "用户密码",
        }
    ]

    let room = [
        {
            key: "accessCode",
            mandatory: "是",
            type: "String",
            description: "房间号",
        }, {
            key: "subject",
            mandatory: "是",
            type: "String",
            description: "房间主题",
        }, {
            key: "creatorId",
            mandatory: "是",
            type: "String",
            description: "房间创建者的userId",
        }, {
            key: "desktopSharerUserId",
            mandatory: "否",
            type: "String",
            description: "桌面共享的成员userId",
        }
    ]
    
    let member = [
        {
            key: "userId",
            mandatory: "是",
            type: "String",
            description: "该成员的userId",
        }, {
            key: "nickName",
            mandatory: "是",
            type: "String",
            description: "该成员的昵称",
        }, {
            key: "avatarUrl",
            mandatory: "是",
            type: "String",
            description: "该成员头像",
        }, {
            key: "status",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "status-member", onChange)
            }}>vmeeting.STATUS.MEMBER</Typography.Link>,
            description: "该成员状态",
        }, {
            key: "mute",
            mandatory: "是",
            type: "Boolean",
            description: "是否静音",
        }, {
            key: "beSeen",
            mandatory: "是",
            type: "Boolean",
            description: "是否打开摄像头",
        }
    ]

    let addressBook = [
        {
            key: "pag",
            mandatory: "否",
            type: <Typography.Link onClick={() => {
                document.getElementById('pag').scrollIntoView()
            }}>vmeeting.Pag</Typography.Link>,
            description: "分页结构",
        }, {
            key: "onOk",
            mandatory: "否",
            type: "Function",
            description: "成功的回调函数",
        }, {
            key: "onError",
            mandatory: "否",
            type: "Function",
            description: "失败的回调函数",
        }
    ]

    let viewMediaMembers = [
        {
            key: "medias",
            mandatory: "是",
            type: <Typography.Text><Typography.Link onClick={() => {
                document.getElementById('media-member').scrollIntoView()
            }}>vmeeting.MediaMember</Typography.Link>数组</Typography.Text>,
            description: "需要选看的成员和媒体信息",
        }
    ]

    let mediaMember = [
        {
            key: "userId",
            mandatory: "是",
            type: "String",
            description: "选看成员userId",
        }, {
            key: "streamId",
            mandatory: "是",
            type: "Number",
            description: "选看成员选到本端的流id",
        }, {
            key: "definition",
            mandatory: "是",
            type: <Typography.Link onClick={() => {
                link("error_code", "definition", onChange)
            }}>vmeeting.MEDIA.DEFINITION</Typography.Link>,
            description: "选看成员的分辨率",
        }
    ]

    let inviteMembers = [
        {
            key: "userIds",
            mandatory: "是",
            type: "String数组",
            description: "邀请成员的userId列表",
        }
    ]

    let kickMembers = [
        {
            key: "userIds",
            mandatory: "是",
            type: "String数组",
            description: "踢出成员的userId列表",
        }
    ]

    let pag = [{
        key: "page",
        mandatory: "否",
        type: "Json字典",
        description: "分页信息",
        subs: [{
                key: "size",
                mandatory: "是",
                type: "Number",
                description: "每页大小",
            }, {
                key: "number",
                mandatory: "是",
                type: "Number",
                description: "页数",
            }
        ]
    }, {
        key: "sort",
        mandatory: "否",
        type: "Json字典",
        description: "排序",
        subs: [{
                key: "field",
                mandatory: "是",
                type: "String",
                description: "排序的字段",
            }, {
                key: "order",
                mandatory: "是",
                type: "Number",
                description: "升序/降序",
            }
        ]
    }, {
        key: "filters",
        mandatory: "否",
        type: "数组",
        description: "过滤条件",
        content: <div>
            <div>说明：组间是或（or）条件，组内是与（and）条件</div>
            <div>举例：</div>
            <div>[</div>
            <div style={{marginLeft: 16}}>[ // 组间第一组 or关系</div>
            <div style={{marginLeft: 32}}>['key1', '=', 2], // 组内第一组 and关系</div>
            <div style={{marginLeft: 32}}>['key2', '!=', 3] // 组内第二组 and关系</div>
            <div style={{marginLeft: 16}}>],</div>
            <div style={{marginLeft: 16}}>[['key3', 'equal', 'a']], // 组间第二组 or关系</div>
            <div style={{marginLeft: 16}}>[['key4', 'not equal', 'b']], // 组间第三组 or关系</div>
            <div style={{marginLeft: 16}}>[['key5', 'like', 'c']] // 组间第四组 or关系</div>
            <div>]</div>
            <div>解释：(key1 == 2 && key2 != 3) || (key3 == 'a') || (key4 != 'b') || (key5 like 'c')</div>
            <div>{"最小的数组，均是三元为一组，第一个是key字段，第二个是操作符，第三个是值。操作符区分数字和字符串，数字用'==' '!=' '<' '>' '<=' '>=' 符号，字符串用'equal' 'not equal' 'like'符号"}</div>
        </div>,
    }, 
    ]

    return <Fragment>
        <Typography.Title>导航</Typography.Title>
        <Anchor className="web-anchor" affix={false}>
            <Anchor.Link href="#sdk-config" title="vmeeting.SdkConfig" />
            <Anchor.Link href="#create-room-config" title="vmeeting.CreateRoomConfig" />
            <Anchor.Link href="#join-room-config" title="vmeeting.JoinRoomConfig" />
            <Anchor.Link href="#address-book-config" title="vmeeting.AddressBookConfig" />
            <Anchor.Link href="#view-meida-member-config" title="vmeeting.ViewMeidaMemberConfig" />
            <Anchor.Link href="#meida-member" title="vmeeting.MeidaMember" />
            <Anchor.Link href="#pag" title="vmeeting.Pag" />
            <Anchor.Link href="#sdk-event" title="vmeeting.SdkEvent" />
            <Anchor.Link href="#session-event" title="vmeeting.SessionEvent" />
            <Anchor.Link href="#profile" title="vmeeting.Profile" />
            <Anchor.Link href="#room" title="vmeeting.Room" />
            <Anchor.Link href="#member" title="vmeeting.Member" />
        </Anchor>
        <Typography>
            <Typography.Title id="sdk-config">vmeeting.SdkConfig</Typography.Title>
            <Typography.Text>SDK的配置信息，一个JSON字典格式的数据</Typography.Text>
            <Table dataSource={sdks} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%" />
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="create-room-config">vmeeting.CreateRoomConfig</Typography.Title>
            <Typography.Text>创建房间时的信息，一个JSON字典格式的数据</Typography.Text>
            <Table dataSource={createRooms} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="join-room-config">vmeeting.JoinRoomConfig</Typography.Title>
            <Typography.Text>加入房间时的信息，一个JSON字典格式的数据</Typography.Text>
            <Table dataSource={joinRooms} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="invite-member-config">vmeeting.InviteMemberConfig</Typography.Title>
            <Typography.Text>邀请用户，一个JSON字典格式的数据</Typography.Text>
            <Table dataSource={inviteMembers} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="kick-member-config">vmeeting.KickMemberConfig</Typography.Title>
            <Typography.Text>踢出用户，一个JSON字典格式的数据</Typography.Text>
            <Table dataSource={kickMembers} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="address-book-config">vmeeting.AddressBookConfig</Typography.Title>
            <Typography.Text>地址簿，Json字典类型</Typography.Text>
            <Table dataSource={addressBook} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="view-media-member-config">vmeeting.ViewMediaMemberConfig</Typography.Title>
            <Typography.Text>选看成员，Json字典类型</Typography.Text>
            <Table dataSource={viewMediaMembers} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="media-member">vmeeting.MediaMember</Typography.Title>
            <Typography.Text>媒体成员，Json字典类型</Typography.Text>
            <Table dataSource={mediaMember} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="pag">vmeeting.Pag</Typography.Title>
            <Typography.Text>分页，Json字典类型</Typography.Text>
            <Table dataSource={pag} pagination={false} className="web-data-structure-table"
                expandable={{
                    defaultExpandAllRows: true,
                    expandedRowRender: record => record.subs ? <Table showHeader={false} dataSource={record.subs} pagination={false} className="web-data-structure-sub-table">
                        <Table.Column title="字段" dataIndex="key" key="key" width="20%" render={(value) => `${record.key}>>${value}`}/>
                        <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                        <Table.Column title="类型" dataIndex="type" key="type"/>
                        <Table.Column title="描述" dataIndex="description" key="description"/>
                    </Table> : <div>{record.content}</div>,
                }}
            >
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description" />
            </Table>

            <Typography.Title id="sdk-event">vmeeting.SdkEvent</Typography.Title>
            <Typography.Text>Sdk事件Listener的Event，vmeeting.Event类型</Typography.Text>
            <Table dataSource={sdkEvents} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="session-event">vmeeting.SessionEvent</Typography.Title>
            <Typography.Text>房间/成员/媒体事件Listener的Event，vmeeting.Event类型</Typography.Text>
            <Table dataSource={sessionEvents} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="profile">vmeeting.Profile</Typography.Title>
            <Typography.Text>个人信息，Json字典类型</Typography.Text>
            <Table dataSource={profiles} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="room">vmeeting.Room</Typography.Title>
            <Typography.Text>房间信息，Json字典类型</Typography.Text>
            <Table dataSource={room} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>

            <Typography.Title id="member">vmeeting.Member</Typography.Title>
            <Typography.Text>成员信息，Json字典类型</Typography.Text>
            <Table dataSource={member} pagination={false} className="web-error-code-table">
                <Table.Column title="字段" dataIndex="key" key="key" width="20%"/>
                <Table.Column title="必填" dataIndex="mandatory" key="mandatory" width="5%"/>
                <Table.Column title="类型" dataIndex="type" key="type"/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
            </Table>
        </Typography>
    </Fragment>
}