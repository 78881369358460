import { Component, } from 'react';
import { Tabs } from 'antd';
import { Alioss } from '../../../component/aliossClient';

import mkIntroduction from './component/introduction';
import mkVerion from './component/version';
import mkCode from './component/code';
import mkApi from './component/api';
import mkErrorCode from './component/errorCode';
import mkFAQ from './component/faq';
import mkDataStructure from './component/dataStructure';

import './web.less'

class Web extends Component {
    
    constructor(props) {
        super(props)

        this.tblRowDownloadOnClick = this.tblRowDownloadOnClick.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            tab: "introduction",
            aliossClient: new Alioss()
        }
    }

    tblRowDownloadOnClick(e, record) {
        let { aliossClient } = this.state;
        try {
            window.location.href = aliossClient.downloadUrl(record.path, record.name);
        } catch (error) {
            console.error(error)
        }
    }

    onChange(key) {
        let { aliossClient } = this.state;
        if (key === "version") {
            aliossClient.fileList('vmeeting-sdk/browser/', (prefix, records) => {
                this.setState({
                    versions: records
                })
            })
        }
        this.setState({
            tab: key
        })
    }

    render() {
        let { tab } = this.state;
        return <Tabs className="web-tabs" onChange={this.onChange} activeKey={tab}>
            <Tabs.TabPane tab="介绍" key="introduction">
                {mkIntroduction(this.onChange)}
            </Tabs.TabPane>
            <Tabs.TabPane tab="版本" key="version">
                {mkVerion(this.state.versions, this.tblRowDownloadOnClick)}
            </Tabs.TabPane>
            <Tabs.TabPane tab="代码示例" key="code">
                {mkCode(this.onChange)}
            </Tabs.TabPane>
            <Tabs.TabPane tab="API" key="api">
                {mkApi(this.onChange)}
            </Tabs.TabPane>
            <Tabs.TabPane tab="数据结构" key="data_structure">
                {mkDataStructure(this.onChange)}
            </Tabs.TabPane>
            <Tabs.TabPane tab="常量" key="error_code">
                {mkErrorCode(this.onChange)}
            </Tabs.TabPane>
            <Tabs.TabPane tab="FAQ" key="faq">
                {mkFAQ(this.onChange)}
            </Tabs.TabPane>
        </Tabs>
    }
}


export default Web
