import { Typography, Descriptions } from 'antd';

export default function mkIntroduction(onChange) {
    return <Typography>
        <Typography.Title>简介</Typography.Title>
        <Typography.Paragraph>
            为了能快速开发出具有视音频能力的WEB系统，音极视推出了全新的<Typography.Text strong>vmeeting-sdk-browser</Typography.Text>
            （以下简称SDK），可以帮助第三方开发者快速接入音视频能力。
        </Typography.Paragraph>
        <Typography.Link onClick={() => onChange("version")}>下载SDK</Typography.Link>
        <Typography.Title>兼容环境</Typography.Title>
        <Typography.Paragraph>SDK基于谷歌WebRTC（Chrome内核）开发而成，只能适用于谷歌Chrome内核的浏览器。目前我们所支持的浏览器及版本有：</Typography.Paragraph>
        <ul className="web-supported-browser-list">
            <li>
                <Typography.Paragraph><Typography.Text strong>Chrome 70+ </Typography.Text><Typography.Text mark>（强烈建议使用Chrome浏览器）</Typography.Text></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph><Typography.Text strong>Microsoft Edge 80+ </Typography.Text></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph><Typography.Text strong>QQ浏览器 10+ </Typography.Text></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph><Typography.Text strong>搜狗浏览器 10+ </Typography.Text></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph><Typography.Text strong>360浏览器 12+ </Typography.Text></Typography.Paragraph>
            </li>
        </ul>
        <Typography.Title>在线体验</Typography.Title>
        <Typography.Paragraph>这里我们提供了一个演示版本Demo，你可以初步体验下在浏览器上相互视频的乐趣。<Typography.Link onClick={() =>
            window.open("https://doc.ludiqiao.com/vmeeting-sdk-demo/index.html")
        }>点我体验</Typography.Link></Typography.Paragraph>
        <Typography.Paragraph>想知道怎么实现的？这里我们开放了Demo源码，你可以随意取用。<Typography.Link onClick={() =>
            onChange("version")
        }>下载Demo</Typography.Link></Typography.Paragraph>

        <Typography.Title>线上产品</Typography.Title>
        <Typography.Paragraph>我们提供了使用SDK打造的一些线上产品供你体验</Typography.Paragraph>
        <ul className="web-supported-browser-list">
            <li>
                <Typography.Paragraph>视频终端产品，打开微信扫码即可体验。<Typography.Link onClick={() =>
                    window.open("https://vmeeting.ludiqiao.com/")
                }>点我体验</Typography.Link></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph>地图调度产品（百度地图），账号/密码：demo/demo。<Typography.Link onClick={() =>
                    window.open("https://mntn.ludiqiao.com/?env=production")
                }>点我体验</Typography.Link></Typography.Paragraph>
            </li>
        </ul>

        <Typography.Title>测试账号</Typography.Title>
        <Typography.Paragraph>你可以使用以下账号进行测试：</Typography.Paragraph>
        <Descriptions bordered column={1} colon={1} size="small" className="web-account" contentStyle={{background: "#FFF"}}>
            <Descriptions.Item label="Appid"><Typography.Text copyable>ab11f95e8bc211eb91061831bf6d8bd5</Typography.Text></Descriptions.Item>
            <Descriptions.Item label="AppCode"><Typography.Text copyable>b704237f8bc211eb860d1831bf6d8bd5</Typography.Text></Descriptions.Item>
            <Descriptions.Item label="Account">
                <Typography.Paragraph copyable>fa2cc6908c4211ebb0261831bf6d8bd5</Typography.Paragraph>
                <Typography.Paragraph copyable>5af1a857087b4a5ea9bec536be2204a1</Typography.Paragraph>
                <Typography.Paragraph copyable>48c3be0adccb4cb9b854bc85abc74eec</Typography.Paragraph>
                <Typography.Paragraph copyable>af7796c4cd2b4d85bcdceb7529b30db8</Typography.Paragraph>
                <Typography.Paragraph copyable>f66d39ec0e724d6b854ba1ee96af617d</Typography.Paragraph>
            </Descriptions.Item>
        </Descriptions>
    </Typography>
}