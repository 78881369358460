import { Typography } from 'antd';

export default function mkCode() {
    return <Typography>
        <Typography.Title>一、添加SDK到工程</Typography.Title>
        <Typography.Paragraph>1、下载vmeeting-sdk-android开发包，解压可直接获取vmeeting-sdk.aar</Typography.Paragraph>
        <Typography.Paragraph>2、拷贝vmeeting-sdk.aar到你的项目app/libs目录</Typography.Paragraph>
        <Typography.Paragraph>3、修改gradle文件（app/build.gradle），添加开发包和依赖</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "implementation(name:'vmeeting-sdk', ext:'aar')\n" +
                    "implementation(\"com.squareup.okhttp3:okhttp:4.9.1\")\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>4、修改AndroidManifest文件（app/src/main/AndroidManifest.xml），添加音视频所需权限</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "<uses-permission android:name=\"android.permission.INTERNET\" />\n" + 
                    "<uses-permission android:name=\"android.permission.ACCESS_NETWORK_STATE\" />\n" + 
                    "<uses-permission android:name=\"android.permission.WRITE_EXTERNAL_STORAGE\" />\n" + 
                    "<uses-permission android:name=\"android.permission.CAMERA\" />\n" + 
                    "<uses-permission android:name=\"android.permission.MODIFY_AUDIO_SETTINGS\" />\n" + 
                    "<uses-permission android:name=\"android.permission.RECORD_AUDIO\" />\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Title>二、初始化SDK</Typography.Title>
        <Typography.Paragraph>1、权限申请及校验</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "String permissions[] = {\n" +
                    "    android.Manifest.permission.WRITE_EXTERNAL_STORAGE,\n" +
                    "    Manifest.permission.CAMERA,\n" +
                    "    Manifest.permission.RECORD_AUDIO\n" +
                    "}\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>以上权限需要动态申请，申请成功并校验app获得了以上权限后，便可以开始加载SDK了。</Typography.Paragraph>
        

        <Typography.Paragraph>2、load SDK</Typography.Paragraph>
        <Typography.Paragraph type="danger">注：1、sdk加载过程是一个耗时操作，在工作线程中执行此函数</Typography.Paragraph>
        <Typography.Paragraph type="danger">2、此类为单例，context参数请使用ApplicationContext</Typography.Paragraph>

        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "private void loadSdk() {\n" +
                    "    final ConfigParam configParam = new ConfigParam();\n" +
                    "    configParam.setAppId(SDK_APP_ID);\n" +
                    "    configParam.setAppCode(SDK_APP_CODE);\n" +
                    "    configParam.setContext(getApplicationContext());\n" +
                    "    String logDir = Environment.getExternalStorageDirectory().getAbsolutePath() + File.separator + \"SDKDemoLog\";\n" +
                    "    configParam.setLogDir(logDir);\n" +
                    "    //每个log文件的大小，以MB为单位\n" +
                    "    configParam.setMaxSizePerLogFile(5);\n" +
                    "    //是否默认使用前置摄像头\n" +
                    "    configParam.setUseFrontCamera(true);\n" +
                    "    ConfSDK.getInstance().init(configParam, statusCallback );\n" +
                    "}\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>statusCallback是SDK状态回调接口的实例，SDK加载状态、用户在线状态，都通过该接口通知到UI，为异步接口。常用状态码如下：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "//SDK初始化成功，可以获取API操作接口实例了\n" +
                    "int SDK_INIT_SUCCESS = 0;\n" +
                    "//用户上线了，代表SDK此时拥有了视频通话能力\n" +
                    "int USER_TO_ONLINE = 1;\n" +
                    "//非法参数\n" +
                    "int INVALID_PARAMS = -1;\n" +
                    "//网络不可用\n" +
                    "int NETWORK_UNAVALIABLE = -2;\n" +
                    "//用户离线，SDK失去视频通话能力\n" +
                    "int USER_TO_OFFLINE = -3;\n" +
                    "//其他异常\n" +
                    "int OTHER_EXCEPTION = -20;\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>3、获取API操作接口</Typography.Paragraph>
        <Typography.Paragraph>在收到SDK状态回调，消息码为StatusCallback.SDK_INIT_SUCCESS后，便可以获取SDK提供的API操作实例了，供应用进行登录、创会、入会等常规操作。</Typography.Paragraph>
        <Typography.Paragraph type="danger">注：大多数API操作是异步的，因此接口回调中需操作UI请切换到主线程</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "case StatusCallback.SDK_INIT_SUCCESS:\n" + 
                    "    confApi = ConfSDK.getInstance().getApi();\n" + 
                    "    //添加API回调接口\n" + 
                    "    confApi.addActionCallback(String tag, ActionCallback callback);\n" + 
                    "    break;\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Title>三、用户登录</Typography.Title>
        <Typography.Paragraph>成功进行视频会议通话，需要建立在用户登录成功的基础上，SDK提供了微信授权、内网账号、license三种登录方式，统一通过StatusCallback接口回调状态到应用层。应用层需对如下状态码做处理：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "case StatusCallback.USER_TO_ONLINE:\n" +
                    "    handleLoginSuccess();\n" +
                    "    break;\n" +
                    "case StatusCallback.USER_TO_OFFLINE:\n" +
                    "    break;\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>状态码解读：</Typography.Paragraph>
        <Typography.Paragraph>A、USER_TO_ONLINE，代表当前用户已上线，拥有创会、入会进行音视频通话的能力；</Typography.Paragraph>
        <Typography.Paragraph>B、USER_TO_OFFLINE，代表当前用户已下线，不在拥有音视频通话的能力。</Typography.Paragraph>
        <Typography.Paragraph type="danger">注：应用层需自行对上述两种全局状态进行处理，标明、或提示用户，并对相应操作作出限制处理</Typography.Paragraph>
        
        <Typography.Paragraph>1、微信登录</Typography.Paragraph>
        <Typography.Paragraph>开发者需自行集成微信登录功能，当成功获取用户微信信息后，可调用如下接口进行登录。</Typography.Paragraph>
        <Typography.Paragraph>微信信息重组格式：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "{\n" +
                    "    \"openId\":\"\",\n" +
                    "    \"unionid\":\"\",\n" +
                    "    \"nickName\":\"\",\n" +
                    "    \"gender\":\"\",\n" +
                    "    \"language\":\"\",\n" +
                    "    \"city\":\"\",\n" +
                    "    \"province\":\"\",\n" +
                    "    \"country\":\"\",\n" +
                    "    \"avatarUrl\":\"\"\n" +
                    "}\n" +
                    "if (confApi != null) {\n" +
                    "    confApi.loginByWechat(@NonNull String wechatInfo);\n" +
                    "}\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>2、内网账号登录</Typography.Paragraph>
        <Typography.Paragraph>3、license登录</Typography.Paragraph>
        <Typography.Paragraph>4、退出登录</Typography.Paragraph>
        <Typography.Paragraph>如退出App、或切换账号，可通过此接口退出登录状态</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "confApi.loginOut(\"changeAccount\");\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Title>四、进行视频会议</Typography.Title>
        <Typography.Paragraph>1、建立会议</Typography.Paragraph>
        <Typography.Paragraph>可通过创建、加入两种方式进入会议，操作结果通过ActionCallback接口回调到应用层。当接收到操作类型为ACTION_CONFERENCE_UPDATED的消息码时，代表会议已经建立成功，可以进入会议界面。示例如下：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "@Override\n" + 
                    "public void actionCallback(final int actionType, String tag, String msg) {\n" + 
                    "    if (!TAG.equals(tag)){\n" + 
                    "        return;\n" + 
                    "    }\n" + 
                    "    runOnUiThread(new Runnable() {\n" + 
                    "        @Override\n" + 
                    "        public void run() {\n" + 
                    "            switch (actionType){\n" + 
                    "                case ConstanceForSDK.APIAction.ACTION_CONFERENCE_UPDATED:\n" + 
                    "                    if (isIntoConfPage){\n" + 
                    "                        return;\n" + 
                    "                    }\n" + 
                    "                    isIntoConfPage = true;\n" + 
                    "                    ConferenceActivity.start(MainActivity.this, msg);\n" + 
                    "                    break;\n" + 
                    "                case ConstanceForSDK.APIAction.ACTION_ERROR:\n" + 
                    "                    ToastUtils.showShortToast(MainActivity.this, \"操作失败\");\n" + 
                    "                    break;\n" + 
                    "            }\n" + 
                    "        }\n" + 
                    "    });\n" + 
                    "}\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph type="danger">注：当会议状态、成员、成员状态发生变化时，也会通过ACTION_CONFERENCE_UPDATED消息码回调，因此需注意进入会议界面后需移除API在之前界面的回调接口，做防重复跳转处理。</Typography.Paragraph>
        <Typography.Paragraph>A、创建会议</Typography.Paragraph>
        <Typography.Paragraph>注：subject为非必须参数</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "confApi.create(String subject);\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>A、加入会议</Typography.Paragraph>
        <Typography.Paragraph>接收到用户输入的会议码后，调用此接口即可加入会议</Typography.Paragraph>
        <Typography.Paragraph>注：confCode为必须参数</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "confApi.join(@NonNull String confCode);\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>2、绑定视频窗口</Typography.Paragraph>
        <Typography.Paragraph>2.1 创建视频窗口视图</Typography.Paragraph>
        <Typography.Paragraph>远端视频窗口需使用SDK组件RemoteVideoView，本端不作限制，会议窗口保证至少一个远端窗口、一个本端预览窗口。布局示例：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "<RelativeLayout\n" +
                    "    android:id=\"@+id/video_container\"\n" +
                    "    android:layout_width=\"match_parent\"\n" +
                    "    android:layout_height=\"match_parent\">\n" +
                    "    <com.anglian.sdk.widget.layout.RemoteVideoView\n" +
                    "        android:id=\"@+id/remote_video0\"\n" +
                    "        android:layout_width=\"match_parent\"\n" +
                    "        android:layout_height=\"match_parent\"/>\n" +
                    "    <com.anglian.sdk.widget.layout.RemoteVideoView\n" +
                    "        android:id=\"@+id/remote_video1\"\n" +
                    "        android:layout_width=\"240dp\"\n" +
                    "        android:layout_height=\"135dp\"\n" +
                    "        android:layout_alignParentStart=\"true\"\n" +
                    "        android:layout_alignParentBottom=\"true\"/>\n" +
                    "    <RelativeLayout\n" +
                    "        android:layout_width=\"wrap_content\"\n" +
                    "        android:layout_height=\"wrap_content\"\n" +
                    "        android:layout_alignParentEnd=\"true\"\n" +
                    "        android:layout_alignParentBottom=\"true\">\n" +
                    "        <SurfaceView\n" +
                    "            android:id=\"@+id/local_video\"\n" +
                    "            android:layout_width=\"240dp\"\n" +
                    "            android:layout_height=\"135dp\" />\n" +
                    "        <TextView\n" +
                    "            android:id=\"@+id/text_name_self\"\n" +
                    "            android:layout_width=\"wrap_content\"\n" +
                    "            android:layout_height=\"wrap_content\"\n" +
                    "            android:layout_gravity=\"center_horizontal\"\n" +
                    "            android:layout_marginTop=\"8dp\"\n" +
                    "            android:background=\"@drawable/shap_radius_half_transparent_oval\"\n" +
                    "            android:padding=\"3dp\"\n" +
                    "            android:paddingStart=\"8dp\"\n" +
                    "            android:paddingEnd=\"8dp\"\n" +
                    "            android:maxLines=\"1\"\n" +
                    "            android:maxEms=\"10\"\n" +
                    "            android:ellipsize=\"end\"\n" +
                    "            android:textColor=\"@color/white\"\n" +
                    "            android:textSize=\"12sp\" />\n" +
                    "    </RelativeLayout>\n" +
                    "</RelativeLayout>\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>2.2 绑定窗口到SDK</Typography.Paragraph>
        <Typography.Paragraph>实例化相应窗口，将远端窗口组合成列表，然后对窗口编号，最后绑定。窗口编号的目的：</Typography.Paragraph>
        <Typography.Paragraph>1、将某成员显示到指定位置；</Typography.Paragraph>
        <Typography.Paragraph>2、显示成员信息到对应窗口位置上；</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "List<RemoteVideoView> remoteVideoViewList = new ArrayList<>();\n" +
                    "remoteVideoViewList.add(remoteVideo0);\n" +
                    "remoteVideoViewList.add(remoteVideo1);\n" +
                    "remoteVideoViewList.add(remoteVideo2);\n" +
                    "//给视频窗口的编号，最多支持八个远端窗口，远端窗口编号范围为0 ~ 7，本地预览窗口默认编号为8\n" +
                    "//当成员发生变化，或者客户端请求把某个成员放到某个窗口位置后，会通过ACTION_MEMBER_POSITION_UPDATED\n" +
                    "//事件通知给调用者，然后客户端需刷新相应窗口的成员信息\n" +
                    "int[] windowIndex = new int[]{0, 1, 2, 8};\n" +
                    "confApi.bindWindowByFreeMode(remoteVideoViewList, surfaceLocal, windowIndex);\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>2.3刷新显示窗口</Typography.Paragraph>
        <Typography.Paragraph>UI需在onResume()、onStop()两个生命周期函数中，分别调用updateWindow(boolean isResume)接口设置视图开始、暂停绘制，示例如下：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "@Override\n" +
                    "public void onResume() {\n" +
                    "    super.onResume();\n" +
                    "    confApi.updateWindow(true);\n" +
                    "}\n" +
                    "@Override\n" +
                    "protected void onPause() {\n" +
                    "    confApi.updateWindow(false);\n" +
                    "    super.onPause();\n" +
                    "}\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        
        <Typography.Paragraph>3、刷新会议信息</Typography.Paragraph>
        <Typography.Paragraph>3.1 刷新会议信息</Typography.Paragraph>
        <Typography.Paragraph>当接收到ConstanceForSDK.APIAction.ACTION_CONFERENCE_UPDATED事件时，表明会议信息发生了改变，根据界面相关展示进行刷新。UI可通过如下接口获取会议信息：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "ConferenceInfo conferenceInfo = confApi.getCurrentConfInfo();\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>3.2 刷新成员信息到视频窗口</Typography.Paragraph>
        <Typography.Paragraph>当接收到ACTION_MEMBER_POSITION_UPDATED 事件时，UI需要通过如下接口获取当前要显示的成员信息列表，并将其刷新到相应视频窗口上。</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "List<MemberInfo> videoMember = confApi.getVideoMember();\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        <Typography.Paragraph>MemberInfo对象中字段positionIndex代表该成员应显示窗口位置（对应上一步编号），UI可根据该字段显示成员信息到之前编号的窗口上。</Typography.Paragraph>
        <Typography.Paragraph type="danger">注：该接口SDK会将自己置于列表最后</Typography.Paragraph>
        
        <Typography.Paragraph>3.3 显示成员列表</Typography.Paragraph>
        <Typography.Paragraph>UI可使用如下接口获取会议信息：</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "ConferenceInfo conferenceInfo = confApi.getCurrentConfInfo();\n" +
                    "List<MemberInfo> allMember = conferenceInfo.getMemberList();\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        
        <Typography.Paragraph>4、退出会议</Typography.Paragraph>
        <Typography.Paragraph>调用退出会议接口后，或者服务器自动结束当前会议，亦或者该成员被踢出会议，接口通过ConstanceForSDK.APIAction.ACTION_CONFERENCE_END消息码通知会议已结束，UI退出会议界面即可。</Typography.Paragraph>
        
        <Typography.Paragraph>4.1 自己退出</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "confApi.exit(String confId, String memberId);\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>4.2 挂断会议</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "confApi.hangUp(String confId);\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Title>五、资源释放</Typography.Title>
        <Typography.Paragraph>1、退出登录</Typography.Paragraph>
        <Typography.Paragraph>2、释放资源</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "confApi.loginOut(\"exit-app\");\n" + 
                    "ConfSDK.getInstance().release();\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>
        
    </Typography>
}