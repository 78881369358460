import { Component, } from 'react';
import { Card } from 'antd';

import './sdk.less'

class Sdk extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
     
        }
    }


    render() {
        let { menus, magicNumber } = this.props;

        return <div className="menu-sdk-list">
            {
                menus.map((menu, index) => {
                    return <Card
                        key={index}
                        className={"menu-card menu-background" + ((magicNumber + index) % 8 + 1)}
                        style={{ width: 240 }}
                        cover={menu.icon}
                        onClick={() => {
                            window.goToMenu(menu.key)
                        }}
                    >
                        <Card.Meta title={menu.title}/>
                    </Card>
                })
            }
        </div>
    }
}


export default Sdk
