import { Typography } from 'antd';

export default function mkCode() {
    return <Typography>
        <Typography.Title>HTML部分</Typography.Title>
        <Typography.Paragraph>1. 引用SDK</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "<script src=\"vmeeting.js\" type=\"text/javascript\"> </script>\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>2. 编写html</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "<video id=\"html_video_local\" style=\"height: 270px; width:480px\" autoPlay muted></video>\n" + 
                    "<video id=\"html_video_remote\" style=\"height: 270px; width:480px\" autoPlay muted></video>\n" + 
                    "<audio id=\"html_audio_remote\" autoPlay></audio>\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Title>JavaScript部分</Typography.Title>
        <Typography.Paragraph>1. 初始化SDK</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "var config = {\n" +
                    "    appid: \"ab11f95e8bc211eb91061831bf6d8bd5\", // 这里填写appid\n" + 
                    "    appCode: \"b704237f8bc211eb860d1831bf6d8bd5\", // 这里填写appCode\n" + 
                    "    account: \"87c31a2db75848abb75180bd103a42c2\", // 这里填写account\n" + 
                    "    eventListener: sdkListener, // 这里填写初始化SDK的Listener\n" +
                    "}\n" + 
                    "var sdk = new vmeeting.Sdk(config)\n" +
                    "console.log(\"version: \" + sdk.version());\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>2. 定义SDK初始化 Listener</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "function sdkListener(e) {\n" + 
                    "    console.log(\"sdkListener type: \" + e.type);\n" + 
                    "    switch (e.type) {\n" +
                    "        case vmeeting.EVENT.SDK.SUCCESS:\n" +
                    "            // SDK初始化成功\n" +
                    "            console.log(\"SDK启动成功！现在你可以输入主题创建房间或输入房间号加入房间\")\n" + 
                    "            console.log(e.profile)\n" + 
                    "            break;\n" +
                    "       case vmeeting.EVENT.SDK.WARN:\n" +
                    "            // 告警（连接断开，正在重连等）\n" +
                    "            console.warn(\"SDK告警！错误码：\" + e.code + \" 详细信息：\" + e.description)\n" + 
                    "            break;\n" + 
                    "       case vmeeting.EVENT.SDK.ERROR:\n" +
                    "            // SDK初始化失败\n" +
                    "            console.error(\"SDK启动失败！错误码：\" + e.code + \" 详细信息：\" + e.description)\n" + 
                    "            break;\n" + 
                    "       default:\n" + 
                    "            break;\n" + 
                    "}\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>3. 创建房间</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "sdk.createRoom({\n" +
                    "    subject: \"xxx讨论房间\", // 这里填写房间主题\n" + 
                    "    eventListener: roomListener, // 这里填写创建房间事件的Listener\n" + 
                    "});\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>4. 定义创建/加入房间 Listener</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "function roomListener(e) {\n" +
                    "    console.log(\"roomListener type: \" + e.type);\n" +
                    "    switch (e.type) {\n" +
                    "        case vmeeting.EVENT.SESSION.ROOM: {\n" +
                    "            switch (e.action) {\n" +
                    "                case vmeeting.ACTION.ROOM.PROCESS: {\n" +
                    "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                    "                        console.error(\"创建/加入房间失败！错误码：\" + e.code + \" 详细信息：\" + e.description)\n" +
                    "                    } else {\n" +
                    "                        console.info(\"正在创建/加入房间！\", e.room)\n" + 
                    "                    }\n" +
                    "                    break;\n" +
                    "                }\n" +
                    "                case vmeeting.ACTION.ROOM.CONNECTED: {\n" +
                    "                    var room = e.room;\n" + 
                    "                    console.log(\"创建/加入房间成功。房间号：\" + room.accessCode + \" 主题：\" + room.subject)\n" +
                    "                    break;\n" +
                    "                }\n" +
                    "                case vmeeting.ACTION.ROOM.ONCHANGE: {\n" +
                    "                    var room = e.room;\n" + 
                    "                    console.log(\"房间信息变化。房间号：\" + room.accessCode + \" 主题：\" + room.subject)\n" +
                    "                    break;\n" +
                    "                }\n" +
                    "                case vmeeting.ACTION.ROOM.TERMINATED: {\n" +
                    "                    var room = e.room;\n" + 
                    "                    console.log(\"退出房间成功。房间号：\" + room.accessCode + \" 主题：\" + room.subject)\n" +
                    "                    break;\n" +
                    "                }\n" +
                    "                default:\n" +
                    "                    break;\n" +
                    "            }\n" +
                    "            break;\n" +
                    "        }\n" + 
                    "        case vmeeting.EVENT.SESSION.MEMBER:\n" +
                    "            switch (e.action) {\n" +
                    "                case vmeeting.ACTION.MEMBER.JOINED: {\n" +
                    "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                    "                        console.error(\"邀请成员失败！错误码：\", e.code, \" 详细信息：\", e.description)\n" +
                    "                    } else {\n" +
                    "                        console.info(\"成员已经加入房间！\", e.member)\n" + 
                    "                        console.info(\"现有成员：\", e.members)\n" + 
                    "                    }\n" +
                    "                    break;\n" +
                    "                }\n" +
                    "                case vmeeting.ACTION.MEMBER.LEAVED: {\n" +
                    "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                    "                        console.error(\"踢出成员失败！错误码：\", e.code, \" 详细信息：\", e.description)\n" +
                    "                    } else {\n" +
                    "                        console.info(\"成员离开房间！\", e.member)\n" +
                    "                        console.info(\"现有成员：\", e.members)\n" + 
                    "                    }\n" +
                    "                    break;\n" +
                    "                }\n" +
                    "                case vmeeting.ACTION.MEMBER.INVITING: {\n" +
                    "                    console.error(\"正在邀请成员\", e.member)\n" +
                    "                    console.info(\"现有成员：\", e.members)\n" + 
                    "                    break;\n" +
                    "                }\n" +
                    "                default:\n" +
                    "                    break;\n" +
                    "            }\n" +
                    "            break;\n" +
                    "        case vmeeting.EVENT.SESSION.MEDIA: {\n" +
                    "            switch (e.action) {\n" +
                    "                case vmeeting.ACTION.MEDIA.RROCESS: {\n" +
                    "                    if (e.code !== vmeeting.CODE.SUCCESS) {\n" +
                    "                        console.error(\"选看成员失败！错误码：\", e.code, \" 详细信息：\", e.description)\n" +
                    "                    }\n" + 
                    "                    break;\n" +
                    "                }\n" +
                    "                case vmeeting.ACTION.MEDIA.ONCHANGE: {\n" +
                    "                    console.info(\"选看成员变化！\", e.media)\n" + 
                    "                    break;\n" +
                    "                }\n" +
                    "                default:\n" +
                    "                    break;\n" +
                    "            }\n" +
                    "            break;\n" +
                    "        }\n" +
                    "        default:\n" +
                    "            break;\n" +
                    "    }\n" +
                    "}\n" +
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>5. 加入房间</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "sdk.joinRoom({\n" +
                    "    accessCode: \"036584584\", // 这里填写房间号\n" + 
                    "    eventListener: roomListener, // 这里填写创建房间事件的Listener\n" + 
                    "});\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>6. 退出房间</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "sdk.exitRoom();\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

        <Typography.Paragraph>7. 销毁房间</Typography.Paragraph>
        <pre className="code-pre">
            <Typography.Paragraph className="web-paragraph-code" code>
                <span>{
                    "sdk.destroyRoom();\n" + 
                    ""
                }</span>
            </Typography.Paragraph>
        </pre>

    </Typography>
}