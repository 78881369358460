import { Component } from 'react';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Result, Button, BackTop } from 'antd';
import { HomeOutlined, ChromeOutlined, ApiOutlined, AndroidOutlined, AppleOutlined } from '@ant-design/icons';

import Home from './pages/home/home'

import Sdk from './pages/sdk/sdk'
import Web from './pages/sdk/web/web'
import Android from './pages/sdk/android/android';

import "./main.less"
import "./layout.less"

class Main extends Component {

    constructor(props) {
        super(props)

        this.menuOnChange = this.menuOnChange.bind(this);

        let sdkSubMenus = [
            { key: "sdk/android", icon: <AndroidOutlined />, title: "安卓终端", app: <Android {...props}/> },
            { key: "sdk/ios", icon: <AppleOutlined />, title: "iOS终端", app: undefined },
            { key: "sdk/vmeeting", icon: <ChromeOutlined />, title: "WEB终端", app: <Web {...props}/> },
        ]
        let sdkMenu = {
            key: "sdk", icon: <ApiOutlined />, title: "SDK", app: <Sdk menus={sdkSubMenus} magicNumber={((Math.random() * 100).toFixed(0))} {...props}/>,
            children: sdkSubMenus
        }

        let menus = [sdkMenu];
        let mainMenu = {
            key: "home", icon: <HomeOutlined />, title: "首页", app: <Home menus={menus} magicNumber={((Math.random() * 100).toFixed(0))} {...props}/>
        }

        this.state = {
            menus: [mainMenu, ...menus]
        }
    }

    menuOnChange(keyOrUrl = undefined, urlParam = "", state = undefined, inner = true, blank = false) {

        let { history } = this.props;
        if (!keyOrUrl) {
            history.push('/')
            return true;
        }

        if (inner) {
            if (keyOrUrl.startsWith("/")){
                keyOrUrl = keyOrUrl.substr(1)
            }
            if (keyOrUrl.endsWith("/")) {
                keyOrUrl = keyOrUrl.substr(0, keyOrUrl.length - 1)
            }
            if (urlParam.startsWith("/")) {
                urlParam = urlParam.substr(1)
            }
            let path = `/${keyOrUrl}/${urlParam}`
            if (!blank) { 
                history.push({
                    pathname: path,
                    state: state
                })
                this.setState({
                    selectedKeys: [keyOrUrl],
                })
            } else {
                window.open(path, "_blank");
            }
        } else {
            if (!blank) {
                window.open(keyOrUrl, "_self")
            } else {
                window.open(keyOrUrl, "_blank")
            }
        }
        
    }

    menuOnClick({ key, keyPath, item, domEvent }) {
        switch (key) {
            case "sdk/monitor/zabbix":
                window.open("https://apizabbixtest.ludiqiao.com:8052/zabbix/index.php", "_blank")
                break;
            
            case "sdk/monitor/fullscreen":
                window.open("/conference/monitor", "_blank")
                break;
            
            default:
                window.goToMenu(key);
                break;
        }

    }

    componentDidMount() {
        window.goToMenu = this.menuOnChange;

        let menuKey = 'home';

        if (menuKey.startsWith("/")){
            menuKey = menuKey.substr(1)
        } 
        if (menuKey.endsWith('/')) {
            menuKey = menuKey.substr(0, menuKey.length - 1)
        }

        let menuKeys = menuKey.split('/');
        let matchLength = 0
        let { menus } = this.state;
        menus?.forEach(menu => {
            menu?.children?.forEach(item => {
                let keys = item.key.split('/')
                if (keys.slice(0, keys.length).toString() === menuKeys.slice(0, keys.length).toString() && keys.length > matchLength) {
                    matchLength = keys.length
                    menuKey = keys.join('/')
                }
            })
        })

        this.setState({
            selectedKeys: [menuKey],
        })

        const pathSnippets = this.props.location.pathname.split('/').filter(i => i);
        if (pathSnippets.length === 0) {
            this.props.history.push("home")
        }
    }

    render() {
        const pathSnippets = this.props.location.pathname.split('/').filter(i => i);
        const breadcrumbItems = pathSnippets.map((_, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
            let tag = undefined;
            for (let item of this.state.menus) {
                if (`/${item.key}` === url) {
                    tag = item.title;
                    break;
                }
                tag = item.children?.find(subItem => `/${subItem.key}` === url)?.title;
            }

            if (tag) {
                return <Breadcrumb.Item key={url}>
                    <Link to={url + "/"}>{tag}</Link>
                </Breadcrumb.Item>
            } else {
                return undefined;
            }
        });

        let routes = [];
        this.state.menus.forEach(item => {
            let component = undefined;
            if (item.children?.length > 0) {
                component = item.children.forEach(subItem => {
                    routes.push({path: subItem.key, component: subItem.app})
                })
                routes.push({path: item.key, component: item.app})
            } else {
                routes.push({path: item.key, component: item.app})
            }
            return component;
        })

        return (<Layout className="layout">
            <BackTop/>
            <Layout.Sider className="layout-left">
                <div className="layout-left-logo" >音极视开放平台</div>
                <Menu className="layout-left-menu"
                    theme="dark"
                    mode="inline"
                    onClick={this.menuOnClick}
                    defaultSelectedKeys={['home']}
                    selectedKeys={this.state.selectedKeys}
                >
                    {
                        this.state.menus.map(item => {
                            let component = undefined;
                            if (item.children?.length > 0) {
                                component = <Menu.SubMenu key={item.key} icon={item.icon} title={item.title}>
                                    {
                                        item.children.map(subItem => {
                                            return <Menu.Item key={subItem.key} icon={subItem.icon}>{subItem.title}</Menu.Item>
                                        })
                                    }
                                </Menu.SubMenu>
                            } else {
                                component = <Menu.Item key={item.key} icon={item.icon}>{item.title}</Menu.Item>
                            }

                            return component;
                        })
                    }
                </Menu>
            </Layout.Sider>
            <Layout className="layout-right">
                <Layout.Header className="layout-right-header"/>
                <Layout.Content className="layout-right-content">
                    <Breadcrumb className="layout-right-content-breadcrumb">{breadcrumbItems}</Breadcrumb>
                    <Switch>
                        {
                            routes.map(route => <Route key={route.path} path={"/" + route.path} render={() => route.component}/>)
                        }
                        <Route render={
                            () => <Result
                                className="layout-right-content-404"
                                status="404"
                                title="404"
                                subTitle="找不到该页面"
                                extra={<Button type="primary" onClick={() => window.goToMenu('home')}>回到首页</Button>}
                            />}
                        />
                    </Switch>
                </Layout.Content>
                <Layout.Footer className="layout-right-footer">Copyright © 2012-2021 Engith. All Rights Reserved.</Layout.Footer>
            </Layout>
        </Layout>);
    }
}


export default withRouter(Main)
