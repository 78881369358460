import { Typography, Descriptions } from 'antd';

export default function mkIntroduction(onChange) {
    return <Typography>
        <Typography.Title>简介</Typography.Title>
        <Typography.Paragraph>
            为了能快速开发出具有视音频能力Android系统，音极视推出了全新的<Typography.Text strong>vmeeting-sdk-android</Typography.Text>
            （以下简称SDK），可以帮助第三方开发者快速接入音视频能力。
        </Typography.Paragraph>
        <Typography.Link onClick={() => onChange("version")}>下载SDK</Typography.Link>
        <Typography.Title>准备工作</Typography.Title>
        <Typography.Paragraph>SDK基于谷歌Android开发而成，只能适用于谷歌Android的设备。</Typography.Paragraph>
        <ul className="web-supported-browser-list">
            <li>
                <Typography.Paragraph>Android Studio <Typography.Text strong>3.0以上版本</Typography.Text></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph>Android API <Typography.Text strong>21或以上</Typography.Text></Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph>Android <Typography.Text strong>5.0以上</Typography.Text>操作系统的设备</Typography.Paragraph>
            </li>
            <li>
                <Typography.Paragraph>申请SDK开发者账号</Typography.Paragraph>
            </li>
        </ul>

        <Typography.Title>测试账号</Typography.Title>
        <Descriptions bordered column={1} colon={1} size="small" className="web-account" contentStyle={{background: "#FFF"}}>
            <Descriptions.Item label="Appid"><Typography.Text copyable>ab11f95e8bc211eb91061831bf6d8bd5</Typography.Text></Descriptions.Item>
            <Descriptions.Item label="AppCode"><Typography.Text copyable>b704237f8bc211eb860d1831bf6d8bd5</Typography.Text></Descriptions.Item>
        </Descriptions>
    </Typography>
}